/* contact */
.form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 15px;
}

.info {
    padding: 15px;
}

.h1title {
    margin: 10px;
    font-weight: 400;
    font-size: 1.8rem;

    @media screen and (max-width: 485px) {
        font-size: 20px;
    }
}

.text {
    margin-bottom: 15px;
    font-weight: 400;
    font-size: 1.1rem;
    margin: 5px 10px;
    width: 90%;

    @media screen and (max-width: 485px) {
        font-size: 0.9rem;
    }
}

/* creator register */
.regform {
    display: flex;
    flex-direction: column;
    border: 0.5px solid #ccc;
    padding: 20px;
    border-radius: 10px;
    gap: 20px;
    max-width: 800px;

    @media screen and (max-width: 460px) {
        padding: 20px 10px;
        max-width: 360px;
    }

    @media screen and (max-width: 425px) {
        padding: 10px;
        max-width: 310px;
    }
}

/* custom tour form */

.customForm {
    border: 0.5px solid light-dark(var(--mantine-color-dark-8), var(--mantine-color-white));
    margin: 10px 20px;
    padding: 20px;
    border-radius: 10px;
    max-width: 400px;
    min-width: 355px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-content: center;
    justify-content: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

    @media screen and (max-width: 820px) {
        max-width: 320px;
        min-width: 280px;
        margin: 10px;
        padding: 12px;
    }
}

.countgroup {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.count {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    width: fit-content;
    height: fit-content;
}

.inquirytext {
    font-size: 1rem;
    font-weight: 400;
    margin-top: 10px;
    margin-bottom: 10px;
}

.answertext {
    font-size: 0.9rem;
    font-weight: 400;
}

.answerlabel {
    width: 55px;
    margin-right: 10px;
    font-size: 0.95rem;
}

.customnumberinput {
    width: 100px;
}

/* to be checked */
.contactgroup {
    display: flex;
    flex-direction: row;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    gap: 30px;
}

.contactstack {
    display: flex;
    flex-direction: column;
    background-color: var(--primary-color);
    border-radius: 10px;
    padding: 20px 10px;
    width: 40%;

    [data-mantine-color-scheme="dark"] & {
        background-color: var(--secondary-color);
    }
}

.mobilewrapper {
    display: flex;
    flex-direction: column;
    border: 0.5px solid #ccc;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    max-width: 800px;

    @media screen and (max-width: 461px) {
        padding: 15px;
        max-width: 340px;
        justify-self: center;
        align-self: center;
    }
}

.customnumberinput {
    width: 90px;
}