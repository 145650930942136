.root {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: black;
    background-size: cover;
    background-position: center;
    background-image: url(/image/home/hero.webp);
    padding: 20px 40px;
    height: 380px;
    @media screen and (max-width: 485px) {
        padding: 15px;
        height: fit-content;
    }
}

.content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    max-width: 55%;
    @media (max-width: 768px) {
        min-width: 90%;
    }
}

.title {
    color: white;
    font-weight: 500;
    line-height: 1.1;
    font-size: 3rem;
    margin-bottom: 10px;
    margin-top: 0px;
    @media screen and (max-width: 485px) {
        font-size: 2rem;
        line-height: 1.15;
    }
}

.description {
    color: white;
    font-family: "Montserrat", sans-serif;
    font-size: 1.3rem;
    line-height: 1.3;
    margin-top: 5px;
    @media screen and (max-width: 485px) {
        font-size: 1.1rem;
        line-height: 1.15;
    }
}
