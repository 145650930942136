.wrapper {
    display: flex;
    flex-direction: column;
    padding: 20px;
    max-width: 700px;
    height: fit-content;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 485px) {
        padding: 20px 15px;
    }
}

.groupwrapper {
    display: flex;
    flex-direction: row;
    padding: 20px;
    max-width: 400px;
    height: fit-content;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 485px) {
        padding: 10px;
    }
}

.text {
    font-size: 1.05rem;
    margin: 12px;
    text-align: center;
    line-height: 1.6;
    max-width: 600px;
}

.boldtext {
    font-weight: 500;
    margin-top: 10px;
    font-size: 1.1rem;
}

.buttongroup {
    display: flex;
    flex-direction: row;
    gap: 15px;
    margin: 6px;
}

.largetext {
    font-size: 1.3rem;
    margin: 10px;
    text-align: center;
    line-height: 1.6;
}
