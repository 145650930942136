.wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: fit-content;

    @media screen and (max-width: 610px) {
        flex-direction: column;
        align-items: center;
        gap: 10px;
    }
}

/* country listing */
.h2heading {
    font-size: 2.2rem;
    margin: 25px 15px;
    text-align: center;
    letter-spacing: 0.8px;
    text-transform: capitalize;
    align-self: center;

    @media screen and (max-width: 460px) {
        font-size: 1.5rem;
        margin: 10px;
    }
}

.seeallbutton {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    width: 200px;
    justify-self: flex-end;
    align-self: flex-end;
    margin: 5px 12px 10px 10px;

    &:hover {
        cursor: pointer;
        color: var(--secondary-color);
    }
}

/* index listing */
.sectionHeading {
    font-size: 2rem;
    font-weight: 400;
    margin-top: 10px;
    margin-bottom: 15px;
    text-align: center;
    line-height: 1.2;
    text-transform: capitalize;
    letter-spacing: 1px;

    @media screen and (max-width: 485px) {
        font-size: 1.5rem;
    }
}

.sectiondescription {
    font-size: 1.4rem;
    font-weight: 400;
    margin-top: 10px;
    margin-bottom: 15px;
    text-align: center;
    line-height: 1.2;
    font-family: "Lora", serif;

    @media screen and (max-width: 485px) {
        font-size: 1rem;
    }
}

.indexlistingStack {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
}

/* listing card */
.pricelabel {
    font-size: 0.9rem;
    font-weight: 400;
    margin: 3px 0px;
    line-height: 1.2;

}

.card {
    margin: 10px;
    border: 0.7px solid #e0e0e0;
    width: 280px;
    height: 430px;
    border-radius: 10px;
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.1);

    &:hover {
        transform: scale(1.02);
        cursor: pointer;
    }

    @media screen and (max-width: 768px) and (min-width: 500px) {
        min-width: 200px;
        max-width: 280px;
        margin: 5px;
    }
}

.cardtitle {
    font-size: 1.15rem;
    font-weight: 400;
    line-height: 1.3;
    text-align: left;
    text-transform: capitalize;
    min-height: 64px;
    max-height: fit-content;
    width: fit-content;
    margin: 1px 0px;

    &:hover {
        color: var(--secondary-color);
        cursor: pointer;

        [data-mantine-color-scheme="dark"] & {
            color: var(--off-white);
        }
    }
}

.cardlabel {
    font-size: 0.8rem;
    font-weight: 400;
    color: black;
    border: 0.5px solid #333;
    border-radius: 45px;
    width: fit-content;
    background-color: var(--primary-color);
    text-align: left;
    padding: 2px 8px;
    margin: 3px 0px;
    text-transform: capitalize;
}



.cardtext {
    font-size: 0.9rem;
    font-weight: 400;
    margin: 3px 12px;
}

.cardinfo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: 156px;
    padding: 9px;
    gap: 3px;
}

.cardactiongroup {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 10px;
    align-items: center;
    padding: 3px 10px;
    margin: 0px;
    height: fit-content;
}

.badgegroup {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-items: flex-start;
    gap: 10px;
    margin: 0px;
}

.overviewModal {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 10px 20px;
    max-width: 500px;

    @media screen and (max-width: 500px) {
        max-width: 320px;
        margin: 10px;
    }
}

.overviewModalText {
    font-size: 1rem;
    margin: 10px;
}

.overviewModalTitle {
    font-family: "Lora", serif;
    font-size: 1.3rem;
    text-align: center;
    margin: 0px;
}

/* //to be reconfirmed/ */
.listingcontainergroup {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

/* also like */
.alsolikeheading {
    font-size: 1.8rem;
    font-weight: 400;
    text-align: center;
    margin: 15px;
    letter-spacing: 0.5px;
    font-family: "Lora", serif;
}

/* image modal */
.listing_img_modal {
    object-fit: cover;
    overflow: hidden;
    border-radius: 10px;
    align-self: start;
    margin: 10px;
    width: 280px;
    height: 210px;
}

/* Accordion */
.exp_heading {
    font-size: 1.4rem;
    font-weight: 500;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 10px;
}

.accordionRoot {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    margin-bottom: 35px;
    align-items: flex-start;
    justify-items: flex-start;
    height: fit-content;
    width: 100%;
    min-width: 500px;

    @media screen and (max-width: 520px) {
        min-width: 280px;
    }
}

.accordionControl {
    height: 50px;
    padding: 12px;
}

.accordionContent {
    padding: 6px 20px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.4;
    font-family: "montserrat", sans-serif;
}

.accordionItem {
    width: 100%;
    border-radius: 15px;
}

.accordin_heading {
    font-size: 1.2rem;
    font-weight: 400;
    font: "lora", serif;
    text-transform: capitalize;

    @media screen and (max-width: 460px) {
        font-size: 1rem;
    }
}

.bullet {
    size: 24px;
}

.timelinecontent {
    font-weight: 400;
    margin-left: 0px;
    margin-right: 20px;
}

.themeicon {
    border-radius: 50px;
    background-color: var(--secondary-color);
}

.spantext {
    font-weight: 500;
    margin-right: 6px;
}

/* action */

.cardbutton {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: fit-content;
    height: fit-content;
    gap: 5px;
    margin: 0px;
}

/* Heading-page */
.heading_container {
    padding: 10px;
    align-items: start;
    gap: 10px;
}

.title_group {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    margin-top: 10px;

    @media screen and (max-width: 485px) {
        gap: 10px;
    }
}

.exptitle {
    font-size: 2rem;
    text-align: left;
    text-transform: capitalize;
    line-height: 1.2;
    margin: 10px 6px;

    @media screen and (max-width: 485px) {
        font-size: 1.5rem;
    }
}

.titleinfostack {
    display: flex;
    flex-direction: column;
    margin-top: 12px;
    margin-bottom: 10px;
    gap: 2px;
}

.headinglabel {
    margin: 0px;
    font-size: 1rem;
    font-family: "montserrat", sans-serif;
    margin: 10px 0px 6px 0px;
}

/* Overview */
.overviewGroup {
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 15px 25px;
    border: 1px solid black;
    background-color: var(--off-white);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    gap: 5px;
    border-radius: 10px;
    height: fit-content;
    margin-top: 10px;
    min-width: 500px;

    [data-mantine-color-scheme="dark"] & {
        background-color: #202020;
        color: var(--off-white);
        border: 1px solid var(--off-white);
    }

    @media screen and (max-width: 520px) {
        min-width: 280px;
    }
}

.section_heading {
    font-size: 1.3rem;
    font-weight: 500;
    margin: 6px 0px;
}

.overview_content {
    line-height: 1.5;
    font-size: 1rem;
    margin-bottom: 5px;
    font-weight: 400;
    margin-top: 5px;
    white-space: pre-wrap;
}

.overview_label {
    line-height: 1.3;
    font-size: 1rem;
    margin-top: 5px;
    font-weight: 400;
    margin-bottom: 0px;
    white-space: pre-wrap;
    font-family: "montserrat", sans-serif;

    @media screen and (max-width: 460px) {
        font-size: 0.9rem;
    }
}

.overview_detail_stack {
    display: flex;
    flex-direction: column;
    gap: 3px;
    margin-top: 10px;
    margin-bottom: 10px;
}

/* affix */

.mobileaffix {
    background-color: white;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    min-height: 55px;
    max-height: 70px;
    padding: 15px;
    border-top: 1px solid #ccc;

    [data-mantine-color-scheme="dark"] & {
        background-color: #202020;
        color: var(--off-white);
        border-top: 1px solid var(--off-white);
    }
}

.affixtitle {
    font-size: 1rem;
    line-height: 1.1;
    margin-right: 10px;
    margin-left: 6px;
    font-family: "Lora", serif;
    letter-spacing: 0.5px;

    @media screen and (max-width: 500px) {
        font-size: 0.9rem;
        max-width: 65%;
        margin-right: 6px;
    }
}

/* safety */

.safetyAccordionItem {
    width: 100%;
    border: 1.5px solid var(--secondary-color);
    min-width: 500px;

    @media screen and (max-width: 500px) {
        min-width: 280px;
    }
}

.safetysubheading {
    font-size: 1.1rem;
    line-height: 1.3;
    margin: 6px 10px;
}

/* listing-detail page */
.detailContainer {
    display: flex;
    flex-direction: row;
    justify-items: flex-start;
    align-items: flex-start;
    gap: 40px;
    margin-top: 20px;

    @media screen and (max-width: 485px) {
        padding: 10px 10px;
    }
}

.detailStack {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 60%;

    @media screen and (max-width: 912px) {
        width: 100%;
    }
}

/* listing-full page */
.listingContainer {
    padding: 10px 40px;

    @media screen and (max-width: 768px) {
        padding: 10px 40px;
    }

    @media screen and (max-width: 485px) {
        padding: 10px 20px;
    }
}

/* Image */

.img_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 15px;

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
    }
}

.feature_img {
    min-width: 300px;
    max-width: 400px;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    object-fit: cover;
    height: 310px;
}

.img_group {
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-auto-flow: column;
    gap: 10px;
    position: relative;
    height: 310px;
    align-content: flex-start;
    align-items: flex-start;
    justify-content: start;
    justify-items: start;
    justify-self: start;
    margin-right: 40px;
    margin-left: 10px;
    overflow-x: auto;
    overflow-y: hidden;
    scrollbar-width: thin;
    scroll-behavior: auto;
}

.listing_img {
    object-fit: cover;
    overflow: hidden;
    border-radius: 10px;
    align-self: start;
}

.imgButton {
    position: absolute;
    bottom: 16px;
    left: 10px;
    border-radius: 10px;
    border: 1px solid black;
    color: black;
    background-color: var(--off-white);
    z-index: 2;
    align-self: flex-end;
    justify-self: flex-start;
    justify-content: flex-start;

    &:hover {
        background-color: var(--off-white);
        color: black;
    }
}

/* carousel */

.carouselcontainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 10px;
}

.carousel {
    width: 400px;
    height: 300px;

    @media screen and (max-width: 460px) {
        width: 360px;
        height: 270px;
    }

    @media screen and (max-width: 380px) {
        width: 280px;
        height: 210px;
    }
}

.carousel_slide {
    border-radius: 10px;
}

.carousel_image {
    overflow: hidden;
    position: relative;
    object-fit: cover;
    border-radius: 10px;

    @media screen and (max-width: 460px) {
        width: 360px;
        height: 270px;
    }

    @media screen and (max-width: 380px) {
        width: 280px;
        height: 210px;
    }
}

/* searchpage */

.searchpage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.cardgroup {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    margin: 0px;
    padding: 0px;
}

/* tbc */
.feature {
    width: fit-content;
    height: auto;
    align-items: center;
    object-fit: cover;
    overflow: hidden;
    margin: 0px;
}

/* tbc multi tab */

.itTab {
    border: 1px solid black;
    margin-top: 10px;

    [data-mantine-color-scheme="dark"] & {
        border: 0.5px solid var(--off-white);
    }
}

.itLabel {
    font-size: 16px;
    font-weight: 400;
}

.itPanel {
    margin-top: 10px;
}

.shareButtonRoot {
    color: black;

    &:hover {
        color: #a08ed3;
    }

    [data-mantine-color-scheme="dark"] & {
        color: var(--off-white);
    }
}

.rating {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
    margin: 5px 0px;
}

.ratinglabel {
    font-size: 0.8rem;
    margin: 0px 3px;
}

.accortext {
    white-space: pre-wrap;
}

/* activity */
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.cardgroup {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: fit-content;

    @media screen and (max-width: 610px) {
        flex-direction: column;
        align-items: center;
        gap: 10px;
    }
}

/* city */

.heading {
    font-size: 2.2rem;
    margin: 30px 15px;
    text-align: center;
    letter-spacing: 1px;
    text-transform: capitalize;

    @media screen and (max-width: 460px) {
        font-size: 1.5rem;
    }
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.mapbox {
    min-width: 280px;
    width: 80%;
    height: 200px;
    border-radius: 10px;
    align-self: center;
    margin: 0px;
}

.notfound {
    display: flex;
    justify-content: center;
}